import { Action, createActionGroup, emptyProps, props } from '@ngrx/store';

export interface Notification {
  title: ConstructorParameters<typeof Notification>[0];
  options?: ConstructorParameters<typeof Notification>[1] & {
    data?: {
      actions?: {
        action: string | Action | (string | Action)[];
        title: string;
        icon?: string;
      }[];
    };
  };
  source?: string;
  foregroundOnlyNotification?: boolean;
}

export const notificationsActions = createActionGroup({
  source: 'Users',
  events: {
    showNotification: props<Notification>(),
    dismiss: emptyProps(),
  },
});
